<template>
  <section>
    <h1>Ops!! Page not found...</h1>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>

</style>